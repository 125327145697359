<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
            <template v-slot:body >
              <b-row >
                <b-col md="4" xs="12" sm="6" class="mb-3">
                  <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
                </b-col>
                <b-col md="8" sm="6" xs="12" class="mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="analyticsAdmissionSchoolStatus()" ></date-range-picker>
                </div>
              </b-col>
              </b-row>
              <b-row>
                <b-col class="container_minheight" md="12" v-if="admissionAnalyticsObj && admissionAnalyticsObj.length > 0">
                  <b-table
                    hover
                    responsive
                    :items="admissionAnalyticsObj"
                    :fields="columns"
                    :no-border-collapse="true"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :current-page="currentPage"
                    :per-page="perPage">
                    <template v-slot:cell(sch_name)="data">
                      <p v-html="data.item.sch_name" class="text-wrap w-100px threeLineOnly vhtmlTag vueHtmlParent">
                      </p>
                    </template>
                    <template v-slot:cell(sch_city)="data">
                      <p v-html="data.item.sch_city" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(sch_country)="data">
                      <p v-html="data.item.sch_country" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <p v-html="data.item.org_name.concat(', ',data.item.org_city,', ',data.item.org_country)" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(org_city)="data">
                      <p v-html="data.item.org_city" class="text-wrap w-100px ">
                      </p>
                    </template>
                    <template v-slot:cell(cour_degree_name)="data">
                      <p class="text-wrap w-100px ">{{data.item.cour_degree_name = data.item.cour_degree_name.replace(/\"/g, "")}}
                      </p>
                    </template>
                    <template v-slot:cell(cour_name)="data">
                      <p class="text-wrap w-100px ">{{data.item.cour_name.replace(/\"/g, "").replace(data.item.cour_degree_name+" - ","")}}
                      </p>
                    </template>
                    <template v-slot:cell(APPLIED)="data">
                      <p v-html="data.item.APPLIED" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(UNIV_APP_ACCEPTED)="data">
                      <p v-html="data.item.UNIV_APP_ACCEPTED" class="text-wrap w-100px ">
                      </p>
                    </template>
                    <template v-slot:cell(STU_APP_ACCEPTED)="data">
                      <p v-html="data.item.STU_APP_ACCEPTED" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(STU_APP_REJECTED)="data">
                      <p v-html="data.item.STU_APP_REJECTED" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(REJECTED)="data">
                      <p v-html="data.item.REJECTED" class="text-wrap w-100px ">
                      </p>
                    </template>
                  </b-table>
                </b-col>
                <b-col class="container_minheight" md="12" v-else>
                  No data found
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="7" md="8" class="my-1">
                </b-col>
                <b-col sm="5" md="4" class="my-1">
                  <b-pagination v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0">
                </b-pagination>
                </b-col>
              </b-row>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.dateRange {
  float: right;
}
</style>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import moment from "moment"
import { analyticsAdmissionDashboard } from "../../../../FackApi/api/analytics/analytics_admission_dashboard"
import config from "../../../../Utils/config.js"
import { socialvue } from "../../../../config/pluginInit"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "Admission_analytics_school_wise",
  data () {
    return {
      apiName: "analytics_admission_state_school_wise",
      cvCardTitle: "Admissions Analytics School Wise",
      admissionAnalyticsObj: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Admission Analytics Response",
      sortBy: "name",
      sortDesc: false,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 14,
      cvSearchText: "Type to Search",
      admissionStateInfo: config.AdmissionStateInfo,
      userProfileUnlockState: config.UserProfileUnlockState,
      columns: [],
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getDateFilter()
    this.analyticsAdmissionSchoolStatus()

    this.cvCardTitle = "Admissions Dashboard for School Students"
    let self = this

    switch (this.userData.user_role) {
      case "USERROLE11111":
      case "USERROLE11118":
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School", key: "sch_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School City", key: "sch_city", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School Country", key: "sch_country", class: "text-left align-text-top  text-wrap w-100", sortable: true },
          { label: "University", key: "org_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Degree", key: "cour_degree_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Specialization", key: "cour_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Application Done", key: "APPLIED", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Offers Sent by Univ", key: "UNIV_APP_ACCEPTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Offers Accepted", key: "STU_APP_ACCEPTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Offers Declined", key: "STU_APP_REJECTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Application Waitlisted", key: "REJECTED", class: "text-left align-text-top text-wrap w-100", sortable: true }
        ]
        break
      case "USERROLE11115":
        // Univ Admin
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School", key: "sch_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School City", key: "sch_city", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "School Country", key: "sch_country", class: "text-left align-text-top  text-wrap w-100", sortable: true },
          { label: "Degree", key: "cour_degree_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Specialization", key: "cour_name", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Application Done", key: "APPLIED", class: "text-left align-text-top text-wrap w-100", sortable: true },
          { label: "Offers Sent by Univ", key: "UNIV_APP_ACCEPTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Offers Accepted", key: "STU_APP_ACCEPTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Offers Declined", key: "STU_APP_REJECTED", class: "text-left align-text-top w-100", sortable: true },
          { label: "Application Waitlisted", key: "REJECTED", class: "text-left align-text-top text-wrap w-100", sortable: true }
        ]
        break
      default:
        break
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * analyticsAdmissionSchoolStatus
     */
    async analyticsAdmissionSchoolStatus () {
      try {
        await this.setDateFilter()
        let admListResp = await analyticsAdmissionDashboard.analyticsAdmissionSchoolStatus(this, this.whereFilter)
        if (admListResp.resp_status) {
          this.admissionAnalyticsObj = admListResp.resp_data.data
          this.totalRows = admListResp.resp_data.count
        }
        else {
          this.toastMsg = admListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analyticsAdmissionSchoolStatus() and Exception:", err.message)
      }
    },
    /**
       * getFormattedDateTime
       */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    }
  }
}
</script>
