import { render, staticRenderFns } from "./AdmissionAnalyticsSchoolWise.vue?vue&type=template&id=d3be2cfa&scoped=true"
import script from "./AdmissionAnalyticsSchoolWise.vue?vue&type=script&lang=js"
export * from "./AdmissionAnalyticsSchoolWise.vue?vue&type=script&lang=js"
import style0 from "./AdmissionAnalyticsSchoolWise.vue?vue&type=style&index=0&id=d3be2cfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3be2cfa",
  null
  
)

export default component.exports